export const generateDestinationTitle = (shop, country, region, resort) => {
	const regionToDisplay = shop === "en-GB" && country === "France" ? country : region;

	if (resort && resort !== regionToDisplay) {
		return `${regionToDisplay}, ${resort}`;
	}

	return regionToDisplay;
};

export const generateHotelName = (hotelName, hotelStars) => {
	return `${hotelName}${hotelStars ? ` ${hotelStars}*` : ""}`;
};

import {
	FETCH_MY_BOOKING,
	FETCH_MY_BOOKINGS,
	PAY_DUE_BOOKING,
	FETCH_MY_BOOKINGS_NOTIFICATION,
	BOOK_EXTERNAL_HTML_REQUEST,
} from "app/actionTypes";
import { getCredentials } from "app/utils/auth";
import axios from "axios";
import axiosWithCredential from "app/utils/http/http";
import env from "app/utils/env";
import { getStore } from "app/configureStore";

export const payDueBooking = paymentPayload => {
	const shop = getStore()?.getState()?.shop;
	const { token } = getCredentials(shop);
	const url = `${env("ACCOUNT_API_URL")}/addPayment`;
	const headers = {
		"Content-type": "application/json",
	};

	// Si on a un token, cad paiement depuis mon compte, alors on n'envoie pas la brand ni le shop
	// Si on n'a pas de token, cas paiement depuis le site institutionnel, alors on doit fourni la brand et la shop
	if (token) {
		headers.Authorization = token;
		delete paymentPayload.brand;
		delete paymentPayload.shop;
	}

	return {
		type: PAY_DUE_BOOKING,
		promise: axios.post(url, paymentPayload, { headers }),
	};
};

export const fetchMyBookings = () => {
	const url = `${env("ACCOUNT_API_URL")}/getBookings`;
	return {
		type: FETCH_MY_BOOKINGS,
		promise: axiosWithCredential.post(
			url,
			{},
			{
				headers: {
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const fetchMyBooking = bookingReference => {
	const url = `${env("ACCOUNT_API_URL")}/getBookings`;
	return {
		type: FETCH_MY_BOOKING,
		promise: axiosWithCredential.post(
			url,
			{ bookingReference },
			{
				headers: {
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const fetchMyBookingsNotification = ({ email, shop, brand }) => {
	const url = `${env("ACCOUNT_API_URL")}/getCustomerRequests/count`;
	return {
		type: FETCH_MY_BOOKINGS_NOTIFICATION,
		promise: axiosWithCredential.get(url, {
			headers: {
				"Content-type": "application/json",
			},
			params: { brandCode: brand, shopCode: shop, email },
		}),
	};
};

export const dispatchBookExternalHtmlRequest = () => ({
	type: BOOK_EXTERNAL_HTML_REQUEST,
});
